<template lang="">
    <div>
        <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          
        </v-btn> -->
        <v-icon v-bind="attrs" color="#E0E0E0"
          v-on="on" dark>mdi-cog</v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Customize Table
        </v-card-title>

        <v-card-text>
          <v-row>
              <v-col col="12">
                  <v-combobox
                    label="Columns To Display"
                    multiple
                    :items="items"
                    v-model="selectedItems"
                    
                    ></v-combobox>
              </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    
    tableProps: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // this.selectedItems = this._cols;
  },
  data() {
    return {
      dialog: false,
      selectedItems: [],
    };
  },
  watch: {
    selectedItems(val) {
      this.$emit("input", val);
    },
  },
};
</script>